.gmap iframe {
    height: 400px;
    width: 100%;
}


.whatsapp {
    position: fixed;
    width: 5%;
    bottom: 4%;
    right: 2%;
    z-index: 100;
}

.phone {
    position: fixed;
    width: 5%;
    bottom: 4%;
    right: 2%;
    z-index: 100;
    margin-bottom: 6%;
}

.whatsapp :hover {
    transform: scale(1.2);
}

.phone :hover {
    transform: scale(1.2);
}

.footer {
    background: rgb(232 232 240);
}

@media (max-width: 1200px) {
    .gmap iframe {
        height: 250px;
    }
}

@media (max-width: 1000px) {
    .whatsapp {
        width: 8%;
        bottom: 4%;
        right: 2%;
    }

    .phone {
        width: 8%;
        bottom: 4%;
        right: 2%;
        margin-bottom: 9%;
    }
}


@media (max-width: 600px) {
    .whatsapp {
        width: 15%;
        bottom: 5%;
        right: 4%;
    }

    .phone {
        width: 15%;
        bottom: 5%;
        right: 4%;
        margin-bottom: 16%;
    }
}

.navbar a {
    color: white !important; 
    font-weight: 500 !important;
    font-size: larger !important;
}

.navbar a:hover {
    color: black !important; 
}

.navbar span {
    color: white !important;
}

.navbar button {
    background-color: white;
}

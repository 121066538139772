.search-bar {
    text-align: center;
}

.search-bar input {
    display: block;
    height: 36px;
    width: 88%;
    padding: 0 16px;
    background: #fff;
    border: 1px solid transparent;
    box-shadow: 0 .0625rem .125rem rgba(0, 0, 0, 0.15);
    border-radius: 3px;
    outline: none;
    font-family: 'Noto Sans', sans-serif;
    font-size: 14px;
    text-align: left;
    text-overflow: ellipsis;
    line-height: 36px;
    margin: 0 0 0 25px;
    -webkit-appearance: none;
}

@media (max-width: 1200px) {
    .details p {
        font-size: calc(0.4rem + 0.9vw) !important;
    }
    
    .test {
        font-size: calc(0.8rem + 0.9vw) !important;
    }

    .test2 {
        font-size: calc(0.5rem + 0.9vw) !important;
    }
}
